










































































































































































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Journal, Quest, QuestTask } from '@/interfaces/journal/journal';
import Vue from 'vue';
import QuestDetail from '@/components/journal/questDetail.vue';
import FirebaseRealtime from '@/classes/firebaseRealtime';

@Component({ components: { QuestDetail } })
export default class JournalView extends Vue {
  private loading = true;
  private selectedQuest: Quest | null = null;
  private newQuestTaskTitle = null;
  private newQuestTaskDesc: string | null = null;
  private showNewQuestTask = false;
  private showDeleteQuestConfirmDialog = false;
  private questToDelete: string | null = null;
  private questDeletionInProgress = false;
  private newQuestLoading = false;
  private editingQuest: Quest | null = null;
  private newQuestVisible = false;
  private error: string | null = null;
  private newQuest: Quest = {
    id: Math.round(Math.random() * 100000).toString(),
    title: null,
    isMainQuest: false,
    index: 1,
    questGiver: null,
    tasks: [],
  };
  private editingDescriptionIndex: string | null = null;
  private editingDescription: string | null = null;
  private singleQuestLoading = false;

  get journal(): Journal {
    return this.$store.getters.journal;
  }

  get journalState(): object {
    const journalState = this.$store.getters.journalState;
    if (typeof journalState === 'undefined' || journalState === null || !journalState) {
      return {};
    }

    return journalState;
  }

  get lockedQuests(): string[] {
    return Object.keys(this.journalState);
  }

  get mainQuests() {
    if (!this.journal || !this.journal.quests) {
      return [];
    }
    return this.getSortedQuests(this.journal.quests.filter((quest) => quest.isMainQuest));
  }

  get sideQuests() {
    if (!this.journal || !this.journal.quests) {
      return [];
    }
    return this.getSortedQuests(this.journal.quests.filter((quest) => !quest.isMainQuest));
  }

  public mounted() {
    this.$store.dispatch('loadJournal').then((journal: Journal) => {
      this.loading = false;
    });
  }

  /**
   * When destroyed, unsubscribe for realtime watchers
   */
  public destroyed() {
    // In case a quest is currently being edited make sure to flag it as not
    if (this.selectedQuest !== null) {
      FirebaseRealtime.unflagQuestInJournal(this.$store.getters.currentGroup, this.selectedQuest.id);
    }
    FirebaseRealtime.clearListeners();
  }

  /**
   * When a quest was finished editing, reload its content
   */
  @Watch('journalState')
  private onJournalStateChange(newValue: object, oldValue: object) {
    // Compare the two states to see if any entry is missing in new object
    const oldEditedValues = Object.keys(oldValue);
    const newEditedValues = Object.keys(newValue);
    // Quick case which should cover 95% of cases: 1 entry was edited and is now finished
    if (newEditedValues.length === 0 && oldEditedValues.length === 1) {
      this.$store.dispatch('loadJournal', false);
    }
  }

  private signalQuestEditing(questId) {
    FirebaseRealtime.flagQuestInJournal(this.$store.getters.currentGroup, questId, this.$store.getters.userData.name);
  }

  private signalQuestEditingStop(questId) {
    FirebaseRealtime.unflagQuestInJournal(this.$store.getters.currentGroup, questId);
  }

  private getSortedQuests(quests: Quest[]) {
    return quests.sort((a, b) => {
      if (a.title === null) {
        a.title = '';
      }
      if (b.title === null) {
        b.title = '';
      }

      return a.index > b.index ? 1 : -1;
    });
  }

  /**
   * Called when a quest is selected in the list to the left
   */
  private onSelectedContent(quest: Quest) {
    // Don't select the content when the editing quest is triggered
    if (this.editingQuest !== null && this.editingQuest.id === quest.id) {
      return;
    }
    this.selectedQuest = quest;
  }

  /**
   * Is called when a new quest shall be created
   */
  private addNewQuest() {
    if (this.newQuest.title === null) {
      return;
    }

    this.newQuestLoading = true;

    // Determine the position of this new quest
    const questsToCompare = this.newQuest.isMainQuest ? this.mainQuests : this.sideQuests;
    this.newQuest.index = questsToCompare.length + 1;

    this.$store
      .dispatch('addNewQuest', this.newQuest)
      .then(() => {
        this.newQuest = {
          id: Math.round(Math.random() * 100000).toString(),
          title: null,
          index: 1,
          isMainQuest: false,
          questGiver: null,
          tasks: [],
        };
        this.newQuestLoading = false;
      })
      .catch((error) => {
        this.error = error;
      });
  }

  private editQuest(quest: Quest) {
    this.editingQuest = JSON.parse(JSON.stringify(quest));
  }

  private deleteQuest() {
    if (this.questToDelete === null) {
      throw new Error('questToDelete is empty');
    }
    this.questDeletionInProgress = true;
    this.$store
      .dispatch('deleteQuest', this.questToDelete)
      .then(() => {
        this.questToDelete = null;
        this.questDeletionInProgress = false;
      })
      .catch((error) => {
        this.error = error;
        this.questDeletionInProgress = false;
      });
  }

  private saveEditingQuest() {
    this.$store
      .dispatch('updateQuest', this.editingQuest)
      .then(() => {
        this.editingQuest = null;
      })
      .catch((error) => {
        this.error = error;
      });
  }

  private moveQuestUp(quest: Quest) {
    if (quest.index === 1) {
      throw new Error('Quest already at highest point');
    }
    const newIndex = quest.index - 1;
    this.$store.dispatch('changeQuestOrder', { quest, newIndex }).catch((error) => {
      this.error = error;
    });
  }

  private moveQuestDown(quest: Quest) {
    const otherQuests = quest.isMainQuest ? this.mainQuests : this.sideQuests;
    if (quest.index === otherQuests.length) {
      throw new Error('Quest already at lowest point');
    }
    const newIndex = quest.index + 1;
    this.$store.dispatch('changeQuestOrder', { quest, newIndex }).catch((error) => {
      this.error = error;
    });
  }

  /**
   * When a new task in a quest shall be created
   */
  private addNewTask(questId: string, newQuestTaskTitle: string) {
    this.singleQuestLoading = true;

    const payload = {
      questId,
      title: newQuestTaskTitle,
    };
    this.$store.dispatch('addNewTask', payload).then(() => {
      if (this.selectedQuest !== null) {
        this.selectedQuest = this.getQuestById(this.selectedQuest.id);
      }
      this.singleQuestLoading = false;
    });
  }

  private addNewDescription(questId: string, questTaskId: string, text) {
    this.singleQuestLoading = true;

    const payload = { questId, questTaskId, text };
    this.$store.dispatch('addNewTaskDescription', payload).then(() => {
      this.newQuestTaskDesc = null;
      this.singleQuestLoading = false;
    });
  }

  /**
   * Responsible for saving changes to the main description of the quest
   */
  private saveQuestDescription(editedDescription: string) {
    if (this.selectedQuest === null) {
      throw new Error('Cannot save editing description, no quest selected');
    }

    this.singleQuestLoading = true;
    this.selectedQuest.description = editedDescription;

    this.$store
      .dispatch('updateQuest', this.selectedQuest)
      .then(() => {
        this.singleQuestLoading = false;
      })
      .catch((error) => {
        this.error = error;
        this.singleQuestLoading = false;
      });
  }

  private deleteQuestTask(questId: string, questTaskId: string) {
    this.singleQuestLoading = true;
    const payload = {
      questId,
      questTaskId,
    };
    this.$store.dispatch('deleteQuestTask', payload).finally(() => {
      this.singleQuestLoading = false;
      // Refetch the selected quest so that it updates properly
      if (this.selectedQuest !== null) {
        this.selectedQuest = this.getQuestById(this.selectedQuest.id);
      }
    });
  }

  private saveEditingDescription(
    questId: string,
    questTaskId: string,
    taskDescriptionIndex: number,
    editingTaskDescription: string,
  ) {
    if (this.selectedQuest === null) {
      throw new Error('Selected quest is null');
    }
    this.singleQuestLoading = true;

    // Find the index of this description
    const index = this.selectedQuest.tasks.findIndex((task) => task.id === questTaskId);
    if (index === -1) {
      throw new Error('QuestTask with id ' + questTaskId + ' not found');
    }

    // Set the new text
    this.selectedQuest.tasks[index].descriptions[taskDescriptionIndex].text = editingTaskDescription;

    this.$store
      .dispatch('updateQuest', this.selectedQuest)
      .then(() => {
        this.singleQuestLoading = false;
      })
      .catch((error) => {
        this.error = error;
        this.singleQuestLoading = false;
      });
  }

  private saveEditingTask(questId: string, questTaskId: string, questTaskTitle: string) {
    if (this.selectedQuest === null) {
      throw new Error('Selected quest is null');
    }
    this.singleQuestLoading = true;

    // Find the index of this description
    const index = this.selectedQuest.tasks.findIndex((task) => task.id === questTaskId);
    if (index === -1) {
      throw new Error('QuestTask with id ' + questTaskId + ' not found');
    }

    // Set the new text
    this.selectedQuest.tasks[index].title = questTaskTitle;

    this.$store
      .dispatch('updateQuest', this.selectedQuest)
      .then(() => {
        this.singleQuestLoading = false;
      })
      .catch((error) => {
        this.error = error;
        this.singleQuestLoading = false;
      });
  }

  private transformIndexToRoman(index) {
    if (index > 10) {
      return 'X' + this.transformIndexToRoman(index - 10);
    }

    switch (index) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
      case 5:
        return 'V';
      case 6:
        return 'VI';
      case 7:
        return 'VII';
      case 8:
        return 'VIII';
      case 9:
        return 'IX';
      case 10:
        return 'X';
      default:
        return index;
    }
  }

  private getQuestIconTransform(index: number) {
    let shrink = 'shrink-4';
    // Do some extra shrinking on 3 or more letter numbers
    if ([7, 8, 13, 14, 17, 18].includes(index + 1)) {
      shrink = 'shrink-6';
    }
    return 'down-6 ' + shrink;
  }

  private getQuestById(questId: string) {
    const foundQuest = this.journal.quests.find((quest) => quest.id === questId);

    if (typeof foundQuest === 'undefined') {
      throw new Error('quest with id ' + questId + ' not found');
    }

    return foundQuest;
  }

  private getQuestLocker(questId) {
    if (typeof this.journalState[questId] === 'undefined') {
      return '';
    }
    return this.journalState[questId];
  }

  @Watch('questToDelete')
  private onQuestToDeleteChange(newValue, oldValue) {
    this.showDeleteQuestConfirmDialog = newValue !== null;
  }
}
