


















































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import TextEditor from '@/components/general/texteditor.vue';
import { Quest, QuestTask } from '@/interfaces/journal/journal';

@Component({ components: { TextEditor } })
export default class QuestDetail extends Vue {
  @Prop()
  private quest!: Quest;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop({ default: false })
  private locked!: boolean;

  private openExpansionPanel = null;

  private questTaskToEdit: QuestTask | null = null;
  private questTaskToDelete: QuestTask | null = null;
  private showDeleteQuestTaskConfirmDialog = false;

  private editingQuestDescription = false;
  private editedQuestDescription: string = '';

  private newQuestTaskTitle: string = '';
  private newDescriptionActive: string | null = null;

  private editingDescriptionIndex: string | null = null;

  private saveQuestDescription() {
    this.editingQuestDescription = false;
    this.$emit('quest:edit:description', this.editedQuestDescription);
  }

  private addNewTask() {
    this.$emit('quest:add:task', this.quest.id, this.newQuestTaskTitle);
    this.newQuestTaskTitle = '';
  }

  private deleteQuestTask() {
    if (this.questTaskToDelete === null) {
      throw new Error('questTaskToDelete is null');
    }
    this.$emit('quest:delete:task', this.quest.id, this.questTaskToDelete.id);
    this.questTaskToDelete = null;
  }

  private saveEditingQuestTaskTitle() {
    if (this.questTaskToEdit === null) {
      throw new Error('No quest to edit');
    }
    this.$emit('quest:edit:task', this.quest.id, this.questTaskToEdit.id, this.questTaskToEdit.title);
    this.questTaskToEdit = null;
  }

  private saveEditingDescription() {
    if (this.editingDescriptionIndex === null) {
      throw new Error('Cannot save editing description, no editingIndex selected');
    }
    const indizes = this.editingDescriptionIndex.split('-');
    if (indizes.length !== 2) {
      throw new Error('editingDescriptionIndex is malformed: ' + this.editingDescriptionIndex);
    }
    const questTaskId = indizes[0];
    const taskDescriptionIndex = indizes[1];
    // Fetch the content from the editor

    const reference = this.$refs[this.editingDescriptionIndex];
    if (typeof reference === 'undefined') {
      throw new Error('Could not find reference');
    }
    const content = (reference[0] as TextEditor).getContent();
    this.$emit('quest:edit:taskdescription', this.quest.id, questTaskId, taskDescriptionIndex, content);
  }

  private startEditDescription(questTaskId, descriptionIndex) {
    this.editingDescriptionIndex = questTaskId + '-' + descriptionIndex;
  }

  private addNewDescription(questTaskId: string) {
    const reference = this.$refs[questTaskId + '-new'];
    if (typeof reference === 'undefined') {
      throw new Error('Could not find reference');
    }
    const content = (reference[0] as TextEditor).getContent();

    // error if description is empty
    if (!content || content.length === 0) {
      throw new Error('Created description is empty');
    }

    this.$emit('quest:add:taskdescription', this.quest.id, questTaskId, content);
    // Reset editor afterwards
    (reference[0] as TextEditor).reset();
    this.newDescriptionActive = null;
  }

  /**
   * Cancel editing the description of a questtask.
   * Reset the editor and clear the editing description.
   */
  private cancelEditingDescription(questTask: QuestTask, descriptionIndex: number) {
    // Get the editor belonging to this reference
    const editorRef = questTask.id + '-' + descriptionIndex;
    const reference = this.$refs[editorRef];
    if (typeof reference === 'undefined') {
      throw new Error('Could not find reference');
    }
    const editor = reference[0] as TextEditor;
    editor.reset();
    this.editingDescriptionIndex = null;
  }

  @Watch('questTaskToDelete')
  private onQuestToDeleteChange(newValue) {
    this.showDeleteQuestTaskConfirmDialog = newValue !== null;
  }

  @Watch('loading')
  private onLoadingChange(newValue) {
    // In case we turn of loading, close open editors
    if (!newValue) {
      this.editingDescriptionIndex = null;
    }
  }

  @Watch('editingQuestDescription')
  private onEditQuestDescription(newValue) {
    if (newValue) {
      this.$emit('quest:edit:start', this.quest.id);
    } else {
      this.$emit('quest:edit:stop', this.quest.id);
    }
  }

  @Watch('newDescriptionActive')
  private onNewDescriptionActive(newValue) {
    if (newValue) {
      this.$emit('quest:edit:start', this.quest.id);
    } else {
      this.$emit('quest:edit:stop', this.quest.id);
    }
  }

  @Watch('editingDescriptionIndex')
  private onEditingDescriptionIndex(newValue) {
    if (newValue) {
      this.$emit('quest:edit:start', this.quest.id);
    } else {
      this.$emit('quest:edit:stop', this.quest.id);
    }
  }

  @Watch('questTaskToEdit')
  private onQuestTaskToEdit(newValue) {
    if (newValue) {
      this.$emit('quest:edit:start', this.quest.id);
    } else {
      this.$emit('quest:edit:stop', this.quest.id);
    }
  }

  /**
   * Triggered when the quest is changed. Reset all stored values and trigger stop
   */
  @Watch('quest.id')
  private onQuestChange(newId, oldId) {
    this.$emit('quest:edit:stop', oldId);
    this.questTaskToEdit = null;
    this.editingDescriptionIndex = null;
    this.newDescriptionActive = null;
    this.openExpansionPanel = null;
  }
}
